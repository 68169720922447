// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---projects-kent-coffee-cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../../Projects/kent-coffee/.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---projects-kent-coffee-cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---projects-kent-coffee-src-pages-404-js": () => import("./../../../Projects/kent-coffee/src/pages/404.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-404-js" */),
  "component---projects-kent-coffee-src-pages-about-coffee-roasting-process-js": () => import("./../../../Projects/kent-coffee/src/pages/about/coffee-roasting-process.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-about-coffee-roasting-process-js" */),
  "component---projects-kent-coffee-src-pages-about-our-story-js": () => import("./../../../Projects/kent-coffee/src/pages/about/our-story.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-about-our-story-js" */),
  "component---projects-kent-coffee-src-pages-barista-training-js": () => import("./../../../Projects/kent-coffee/src/pages/barista-training.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-barista-training-js" */),
  "component---projects-kent-coffee-src-pages-contact-js": () => import("./../../../Projects/kent-coffee/src/pages/contact.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-contact-js" */),
  "component---projects-kent-coffee-src-pages-espresso-machine-repairs-js": () => import("./../../../Projects/kent-coffee/src/pages/espresso-machine-repairs.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-espresso-machine-repairs-js" */),
  "component---projects-kent-coffee-src-pages-espresso-machines-js": () => import("./../../../Projects/kent-coffee/src/pages/espresso-machines.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-espresso-machines-js" */),
  "component---projects-kent-coffee-src-pages-index-js": () => import("./../../../Projects/kent-coffee/src/pages/index.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-index-js" */),
  "component---projects-kent-coffee-src-pages-privacy-js": () => import("./../../../Projects/kent-coffee/src/pages/privacy.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-privacy-js" */),
  "component---projects-kent-coffee-src-pages-products-barista-supplies-js": () => import("./../../../Projects/kent-coffee/src/pages/products/barista-supplies.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-products-barista-supplies-js" */),
  "component---projects-kent-coffee-src-pages-products-coffee-beans-js": () => import("./../../../Projects/kent-coffee/src/pages/products/coffee-beans.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-products-coffee-beans-js" */),
  "component---projects-kent-coffee-src-pages-products-hot-chocolate-js": () => import("./../../../Projects/kent-coffee/src/pages/products/hot-chocolate.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-products-hot-chocolate-js" */),
  "component---projects-kent-coffee-src-pages-products-shott-syrup-js": () => import("./../../../Projects/kent-coffee/src/pages/products/shott-syrup.js" /* webpackChunkName: "component---projects-kent-coffee-src-pages-products-shott-syrup-js" */)
}

